<template>
  <a :href="`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}&hashtags=${encodedHashtags}`" target="_blank">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 400 400" style="enable-background:new 0 0 400 400;" :width="width" :height="height" xml:space="preserve"
    >
      <g id="Logo__x2014__FIXED">
        <path style="fill:#FFFFFF;" d="M153.6,301.6c94.3,0,145.9-78.2,145.9-145.9c0-2.2,0-4.4-0.1-6.6c10-7.2,18.7-16.3,25.6-26.6
          c-9.2,4.1-19.1,6.8-29.5,8.1c10.6-6.3,18.7-16.4,22.6-28.4c-9.9,5.9-20.9,10.1-32.6,12.4c-9.4-10-22.7-16.2-37.4-16.2
          c-28.3,0-51.3,23-51.3,51.3c0,4,0.5,7.9,1.3,11.7c-42.6-2.1-80.4-22.6-105.7-53.6c-4.4,7.6-6.9,16.4-6.9,25.8
          c0,17.8,9.1,33.5,22.8,42.7c-8.4-0.3-16.3-2.6-23.2-6.4c0,0.2,0,0.4,0,0.7c0,24.8,17.7,45.6,41.1,50.3c-4.3,1.2-8.8,1.8-13.5,1.8
          c-3.3,0-6.5-0.3-9.6-0.9c6.5,20.4,25.5,35.2,47.9,35.6c-17.6,13.8-39.7,22-63.7,22c-4.1,0-8.2-0.2-12.2-0.7
          C97.7,293.1,124.7,301.6,153.6,301.6"
        />
      </g>
    </svg>ツイート
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/** ツイートボタン表示のコンポーネント */
export default defineComponent({
  name: 'TweetButton',
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    hashtags: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      id: 'tweetButton' + (Math.random() * 1000000).toFixed(0),
      encodedTitle: '',
      encodedUrl: ''
    };
  },
  computed: {
    encodedHashtags: function () {
      let hashtags = 'パズドラ,mtPADmDB';
      if (this.hashtags) { hashtags += ',' + this.hashtags; }
      return encodeURIComponent(hashtags);
    }
  },
  watch: {
    '$route': 'createButton'
  },
  mounted: function () {
    this.createButton();
  },
  methods: {
    createButton: function () {
      this.encodedTitle = encodeURIComponent(document.title);
      this.encodedUrl = encodeURIComponent(document.location.href);
    }
  }
});
</script>

<style scoped>
  a {
    display: inline-block;
    color: #FFFFFF;
    text-decoration: none;
    background: #1DA1F2;
    border-radius: 4px;
    font-size: 14px;
    margin: 2px;
    padding: 2px 4px 0px 0px;
  }
</style>
