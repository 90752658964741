<template>
  <img
    :src="getImageUrl(no)"
    v-bind="$attrs"
    class="awakenIcon"
    :title="createText(no)"
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { constData } from '../mtpadmdb';

/** 覚醒アイコンのURL。（実際はBASE64） */
const g_imageUrls = [
  null,
  require("../../public/image/awaken/1.png"),
  require("../../public/image/awaken/2.png"),
  require("../../public/image/awaken/3.png"),
  require("../../public/image/awaken/4.png"),
  require("../../public/image/awaken/5.png"),
  require("../../public/image/awaken/6.png"),
  require("../../public/image/awaken/7.png"),
  require("../../public/image/awaken/8.png"),
  require("../../public/image/awaken/9.png"),
  require("../../public/image/awaken/10.png"),
  require("../../public/image/awaken/11.png"),
  require("../../public/image/awaken/12.png"),
  require("../../public/image/awaken/13.png"),
  require("../../public/image/awaken/14.png"),
  require("../../public/image/awaken/15.png"),
  require("../../public/image/awaken/16.png"),
  require("../../public/image/awaken/17.png"),
  require("../../public/image/awaken/18.png"),
  require("../../public/image/awaken/19.png"),
  require("../../public/image/awaken/20.png"),
  require("../../public/image/awaken/21.png"),
  require("../../public/image/awaken/22.png"),
  require("../../public/image/awaken/23.png"),
  require("../../public/image/awaken/24.png"),
  require("../../public/image/awaken/25.png"),
  require("../../public/image/awaken/26.png"),
  require("../../public/image/awaken/27.png"),
  require("../../public/image/awaken/28.png"),
  require("../../public/image/awaken/29.png"),
  require("../../public/image/awaken/30.png"),
  require("../../public/image/awaken/31.png"),
  require("../../public/image/awaken/32.png"),
  require("../../public/image/awaken/33.png"),
  require("../../public/image/awaken/34.png"),
  require("../../public/image/awaken/35.png"),
  require("../../public/image/awaken/36.png"),
  require("../../public/image/awaken/37.png"),
  require("../../public/image/awaken/38.png"),
  require("../../public/image/awaken/39.png"),
  require("../../public/image/awaken/40.png"),
  require("../../public/image/awaken/41.png"),
  require("../../public/image/awaken/42.png"),
  require("../../public/image/awaken/43.png"),
  require("../../public/image/awaken/44.png"),
  require("../../public/image/awaken/45.png"),
  require("../../public/image/awaken/46.png"),
  require("../../public/image/awaken/47.png"),
  require("../../public/image/awaken/48.png"),
  require("../../public/image/awaken/49.png"),
  require("../../public/image/awaken/50.png"),
  require("../../public/image/awaken/51.png"),
  require("../../public/image/awaken/52.png"),
  require("../../public/image/awaken/53.png"),
  require("../../public/image/awaken/54.png"),
  require("../../public/image/awaken/55.png"),
  require("../../public/image/awaken/56.png"),
  require("../../public/image/awaken/57.png"),
  require("../../public/image/awaken/58.png"),
  require("../../public/image/awaken/59.png"),
  require("../../public/image/awaken/60.png"),
  require("../../public/image/awaken/61.png"),
  require("../../public/image/awaken/62.png"),
  require("../../public/image/awaken/63.png"),
  require("../../public/image/awaken/64.png"),
  require("../../public/image/awaken/65.png"),
  require("../../public/image/awaken/66.png"),
  require("../../public/image/awaken/67.png"),
  require("../../public/image/awaken/68.png"),
  require("../../public/image/awaken/69.png"),
  require("../../public/image/awaken/70.png"),
  require("../../public/image/awaken/71.png"),
  require("../../public/image/awaken/72.png"),
  require("../../public/image/awaken/73.png"),
  require("../../public/image/awaken/74.png"),
  require("../../public/image/awaken/75.png"),
  require("../../public/image/awaken/76.png"),
  require("../../public/image/awaken/77.png"),
  require("../../public/image/awaken/78.png"),
  require("../../public/image/awaken/79.png"),
  require("../../public/image/awaken/80.png"),
  require("../../public/image/awaken/81.png"),
  require("../../public/image/awaken/82.png"),
  require("../../public/image/awaken/83.png"),
  require("../../public/image/awaken/84.png"),
  require("../../public/image/awaken/85.png"),
  require("../../public/image/awaken/86.png"),
  require("../../public/image/awaken/87.png"),
  require("../../public/image/awaken/88.png"),
  require("../../public/image/awaken/89.png"),
  require("../../public/image/awaken/90.png"),
  require("../../public/image/awaken/91.png"),
  require("../../public/image/awaken/92.png"),
  require("../../public/image/awaken/93.png"),
  require("../../public/image/awaken/94.png"),
  require("../../public/image/awaken/95.png"),
  require("../../public/image/awaken/96.png"),
  require("../../public/image/awaken/97.png"),
  require("../../public/image/awaken/98.png"),
  require("../../public/image/awaken/99.png"),
  require("../../public/image/awaken/100.png"),
  require("../../public/image/awaken/101.png"),
  require("../../public/image/awaken/102.png"),
  require("../../public/image/awaken/103.png"),
  require("../../public/image/awaken/104.png"),
  require("../../public/image/awaken/105.png"),
  require("../../public/image/awaken/106.png"),
  require("../../public/image/awaken/107.png"),
  require("../../public/image/awaken/108.png"),
  require("../../public/image/awaken/109.png"),
  require("../../public/image/awaken/110.png"),
  require("../../public/image/awaken/111.png"),
  require("../../public/image/awaken/112.png"),
  require("../../public/image/awaken/113.png"),
  require("../../public/image/awaken/114.png"),
  require("../../public/image/awaken/115.png"),
  require("../../public/image/awaken/116.png"),
  require("../../public/image/awaken/117.png"),
  require("../../public/image/awaken/118.png"),
  require("../../public/image/awaken/119.png"),
  require("../../public/image/awaken/120.png"),
  require("../../public/image/awaken/121.png"),
  require("../../public/image/awaken/122.png"),
  require("../../public/image/awaken/123.png"),
  require("../../public/image/awaken/124.png"),
  require("../../public/image/awaken/125.png"),
  require("../../public/image/awaken/126.png"),
  require("../../public/image/awaken/127.png"),
  require("../../public/image/awaken/128.png"),
  require("../../public/image/awaken/129.png"),
  require("../../public/image/awaken/130.png"),
  require("../../public/image/awaken/131.png"),
];

/** アイコン画像のURLを取得する。 */
export function getAwakenIconUrl(no: number) {
  return g_imageUrls[no] || `./image/awaken/${no}.png`;
}

/** 覚醒アイコンを表示するコンポーネント。 */
export default defineComponent({
  name: 'AwakenIcon',
  props: {
    /** 表示する覚醒の番号。 */
    'no': {
      type: Number,
      default: null
    }
  },
  methods: {
    /** アイコン画像のURLを取得する。 */
    getImageUrl:  getAwakenIconUrl,
    /** 説明文を作成する。 */
    createText: function (no: number) {
      const awakenInfo = constData.awakenTable[no];
      if (!awakenInfo) { return undefined; }
      return awakenInfo.name + '\n\n' + awakenInfo.description;
    }
  }
});
</script>
