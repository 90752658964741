import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49866e16"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasImage && !_ctx.attributes)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["monsterIcon", _ctx.classObject]),
        style: _normalizeStyle(_ctx.iconSizeStyleObject)
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.linkTag), { to: _ctx.routerLinkObject }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("img", {
              src: _ctx.iconPath,
              alt: _ctx.monsterNoAndName,
              key: `icon${_ctx.no}`
            }, null, 8, _hoisted_1))
          ]),
          _: 1
        }, 8, ["to"]))
      ], 6))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["monsterIcon monsterIconDummy", _ctx.classObject]),
        style: _normalizeStyle(_ctx.iconSizeStyleObject)
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.linkTag), { to: _ctx.routerLinkObject }, {
          default: _withCtx(() => [
            (_ctx.hasAttr0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "attr attr1",
                  src: _ctx.attrPath0
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true),
            (_ctx.hasAttr1)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "attr attr2",
                  src: _ctx.attrPath1
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.hasAttr2)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  class: "attr attr3",
                  src: _ctx.attrPath2
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (!isNaN(_ctx.no))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: "iconDummy",
                  style: _normalizeStyle({ fontSize: _ctx.fontSize, lineHeight: '4em' })
                }, _toDisplayString(_ctx.no || '????'), 5))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["to"]))
      ], 6))
}