import { createApp, defineAsyncComponent } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import $ from 'jquery';
const jQuery = $;
import Bootstrap from 'bootstrap'; // eslint-disable-line no-unused-vars

import App from './App.vue';
import router from './router';
import store from './store';

import './auth';

import TweetButton from './components/tweetButton.vue';
import AwakenIcon from './components/awakenIcon.vue';
import MonsterIcon from './components/monsterIcon.vue';
const MonsterIncrementalSearch = defineAsyncComponent(() => import('./components/monsterIncrementalSearch.vue'));
const Pagination = defineAsyncComponent(() => import('./components/pagination.vue'));
const SkillIncrementalInput = defineAsyncComponent(() => import('./components/skillIncrementalInput.vue'));
const AwakenSelect = defineAsyncComponent(() => import('./components/awakenSelect.vue'));
const AttrSelect = defineAsyncComponent(() => import('./components/attrSelect.vue'));
const CommentList = defineAsyncComponent(() => import('./components/commentList.vue'));
const SlideUpToggle = defineAsyncComponent(() => import('./components/slideUpToggle.vue'));


// ルーターのインスタンスをrootとなるVueインスタンスに渡します
const app = createApp(App)

app.use(router)
app.use(store)

app.component('awaken-icon', AwakenIcon);
app.component('monster-icon', MonsterIcon);
app.component('monster-incremental-search', MonsterIncrementalSearch);
app.component('pagination', Pagination);
app.component('skill-incremental-input', SkillIncrementalInput);
app.component('tweet-button', TweetButton);
app.component('awaken-select', AwakenSelect);
app.component('attr-select', AttrSelect);
app.component('comment-list', CommentList);
app.component('slide-up-toggle', SlideUpToggle);

app.mount("#app")
