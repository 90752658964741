import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _mergeProps({
    src: _ctx.getImageUrl(_ctx.no)
  }, _ctx.$attrs, {
    class: "awakenIcon",
    title: _ctx.createText(_ctx.no)
  }), null, 16, _hoisted_1))
}